import React from "react";
import "./Skeleton.css";

const Skeleton = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-container">
        <div className="skeleton-navbar"></div>
        <div className="skeleton-content">
          <div className="skeleton-sidebar"></div>
          <div className="skeleton-main-content">
            <div className="skeleton-card"></div>
            <div className="skeleton-card"></div>
            <div className="skeleton-card"></div>
          </div>
        </div>
      </div>
      <div className="skeleton-navbar"></div>
      <div className="skeleton-content">
        <div className="skeleton-sidebar"></div>
        <div className="skeleton-main-content">
          <div className="skeleton-section">
            <div className="skeleton-card"></div>
            <div className="skeleton-card"></div>
            <div className="skeleton-card"></div>
          </div>
          <div className="skeleton-section">
            <div className="skeleton-card"></div>
            <div className="skeleton-card"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
