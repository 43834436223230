import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expertData: {},
  expertProfile: {},
  isRegistered: false,
  expertToken: {},
  continueFlag: false,
  phaseId: 0
};

const expertSlice = createSlice({
  name: "expert",
  initialState,
  reducers: {
    setExpertData: (state, action) => {
      state.expertData = action.payload;
    },
    setExpertProfile: (state, action) => {
      state.expertProfile = action.payload;
    },
    setRegister: (state, action) => {
      state.isRegistered = action.payload;
    },
    setExpertToken: (state, action) => {
      state.expertToken = action.payload;
    },
    setContinueFlag: (state, action) => {
      state.continueFlag = action.payload;
    },
    setPhaseId: (state, action) => {
      state.phaseId = action.payload;
    }
  },
});

export const { setExpertData, setRegister, setExpertToken, setExpertProfile, setContinueFlag, setPhaseId } = expertSlice.actions;

export default expertSlice.reducer;
