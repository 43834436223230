import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  selectedExpert: {},
  selectedLearner: {},
  isExpertOpen: false,
  isLearnerOpen: false,
  selectedBasicExpert: {},
  isBasicExpertOpen: false,
  experts: [],
  learners: [],
};

const adminSlice = createSlice({
  name: "adminData",
  initialState,
  reducers: {
    setNotificationData: (state, action) => {
      state.notifications = action.payload;
    },
    setSelectedExpert: (state, action) => {
      state.selectedExpert = action.payload;
    },
    setIsExpertOpen: (state, action) => {
      state.isExpertOpen = action.payload;
    },
    setSelectedLearner: (state, action) => {
      state.selectedLearner = action.payload;
    },
    setIsLearnerOpen: (state, action) => {
      state.isLearnerOpen = action.payload;
    },
    resetAllAdminData: (state) => {
      state.notifications = [];
      state.selectedExpert = {};
      state.selectedLearner = {};
      state.isExpertOpen = false;
      state.isLearnerOpen = false;
      state.experts = [];
      state.selectedBasicExpert = {};
      state.isBasicExpertOpen = false;
    },
    setExperts: (state, action) => {
      state.experts = action.payload;
    },
    setSelectedBasicExpert: (state, action) => {
      state.selectedBasicExpert = action.payload;
    },
    setIsBasicExpertOpen: (state, action) => {
      state.isBasicExpertOpen = action.payload;
    },
    setLearners: (state, action) => {
      state.learners = action.payload;
    },
  },
});

export const {
  setNotificationData,
  setSelectedExpert,
  setIsExpertOpen,
  resetAllAdminData,
  setExperts,
  setIsBasicExpertOpen,
  setSelectedBasicExpert,
  setLearners,
  setSelectedLearner,
  setIsLearnerOpen,
} = adminSlice.actions;

export default adminSlice.reducer;
